import React from "react";
import AppSideBar from "../components/navigation/AppSideBar";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Στατιστική Ανάλυση Επιχειρηματικότητας</title>
      </Helmet>
      <AppSideBar id="app-side-bar">{children}</AppSideBar>
    </>
  );
};

export default Layout;
